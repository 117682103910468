import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Modal,
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Button,
} from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import { CSVLink } from "react-csv";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  getAllProducts,
  getStatistics,
} from "../../../store/actions/licActions";


import DTable from "../../components/Table";

//const Orders = () => {
const LicenseKeys = ({ match, props, history }) => {
  const dispatch = useDispatch();
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const statistics = useSelector((state) => state.lic.statistics);
  const products = useSelector((state) => state.lic.products);

  const [xlsData, setXlsData] = useState([]);

  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);


  const [activePage, setActivePage] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [filter, setFilter] = useState("trial");
  const [product, setProduct] = useState("dbm");

  const [generateKeyModal, setGenerateKeyModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("select");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [totalKeys, setTotalKeys] = useState("1");
  const [phone, setPhone] = useState("");
  const [note, setNote] = useState("");
  const [errors, setErrors] = useState({});

  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 25, 50, 100];
  const [maxPage, setMaxPage] = useState(1);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    loadKeys();
  }, [page, pageSize]);

  // Data Table End

  const csvInstance = useRef(null);
  useEffect(() => {
    if (
      xlsData &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setXlsData([]);
      });
    }  }, [xlsData]);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadKeys();
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getStatistics());
  }, [dispatch]);

  useEffect(() => {
    loadKeys();
    setProduct(match.params.product);

    setTitle();

  }, [match]);


  const setTitle = () => {
    if (products) {
      var product = products.find((x) => x.code == match.params.product);
      console.log("product - " + JSON.stringify(product));
      //document.getElementById("nav_title").innerHTML = product.name??"";

      if (product) {
        const div = document.getElementById("nav_title");
        div.innerHTML = product.name ?? "";
      }
    }
  };

  useEffect(() => {
    setTitle();
  }, [statistics]);

  useEffect(() => {
    loadKeys();
  }, [filter]);



  const loadKeys = async () => {
    setLoading(true);

    const { data } = await axiosInstance.post("reseller/all-licenses", {
      filter: filter,
      product_code: match.params.product,
      search: search,
      is_trial:true,
      page: page,
      limit: pageSize,
    });

    if (data.status) {
      setKeys(data.keys);
      var maxPage2 = Math.ceil(data.total / pageSize);
      console.log("max page - " + maxPage2);
      setMaxPage(maxPage2);
      setLoading(false);
     

    }
  };

  const columns = [
    {
      Header: "SN",
      accessor: "_id",
      sort: false,
      accessor: "",

      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },
    {
      Header: "Key",
      accessor: "key",
      //colspan:2,
      //width: getColumnWidth(rows, 'key', 'Key'),
      //style: { width: '40%', textAlign: 'center' },
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <span
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {
              navigator.clipboard.writeText(d.key);
            }}
          >
            {d.key}
          </span>
        );
        //return <input  className="form-control" style={{ width: '40%', textAlign: 'center' }} type="text" value={d.key} readOnly={true}></input>;
      },
    },
    {
      Header: "User",
      accessor: "name",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <div>
            {d.name}
            <br />
            <small>{d.phone}</small>
            <br />
            <small>{d.email}</small>
            <br />
            <small>{d.place}</small>
            <br />
            <small>{d.country}</small>
          </div>
        );
      },
    },

    {
      Header: "Valid",
      accessor: "valid",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.valid + " Days";
      },
    },

    {
      Header: "Status",
      accessor: "status",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return <div className="text-center">{keyStatus(d)}</div>;
      },
    },

    {
      Header: "Enable",
      accessor: "enable",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <div className="text-center">
            {d.enable ? (
              <Badge variant="success light">Yes</Badge>
            ) : (
              <Badge variant="danger light">No</Badge>
            )}
          </div>
        );
      },
    },

    {
      Header: "Active",
      accessor: "activatedAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.activateAt
          ? format(new Date(d.activateAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Expire",
      accessor: "expireAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.expireAt
          ? format(new Date(d.expireAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    // {
    //   Header: "Note",
    //   accessor: "note",
    //   sort: true,
    // },

    {
      Header: "Created",
      accessor: "createdAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.createdAt
          ? format(new Date(d.createdAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Action",
      accessor: "",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;

        return (
          <Dropdown>
            <Dropdown.Toggle variant="" className="table-dropdown icon-false">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                                {d.enable ? (
                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => onChangeLicStatus(d, false)}
                                  >
                                    Disable
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => onChangeLicStatus(d, true)}
                                  >
                                    Enable
                                  </Dropdown.Item>
                                )}

                                {d.status == 1 ? (
                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => onRemoveDevice(d)}
                                  >
                                    Remove Device
                                  </Dropdown.Item>
                                ) : (
                                  <></>
                                )}
                              </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  const onChangeLicStatus = async (lic, status) => {
    const { data } = await axiosInstance.post("reseller/change-lic-status", {
      license_id: lic.id,
      status: status,
    });

    if (data.status === false) {
      //toast.error(data.message);
      swal("Change License Status", data.message, "error");
    } else {
      swal("Change License Status", data.message, "success");
      // setTimeout(() => {
      //   loadKeys();
      // }, 500);

      var dummy = [...keys];

      const index = dummy.findIndex((x) => x.id === lic.id);

      dummy[index].enable = status;
      setKeys(dummy);
    
    }
  };

  const onRemoveDevice = async (lic) => {
    const { data } = await axiosInstance.post("reseller/remove-lic-device", {
      license_id: lic.id,
    });

    if (data.status === false) {
      swal("Remove Device", data.message, "error");
    } else {
      swal("Remove Device", data.message, "success");

      var dummy = [...keys];
      const index = dummy.findIndex((x) => x.id === lic.id);
      dummy[index].status = 0;
      setKeys(dummy);
     
    }
  };


  const initXlsData = async () => {

    const { data } = await axiosInstance.post("reseller/all-licenses", {
      filter: filter,
      product_code: match.params.product,
      search: search,
      is_trial:true,
      page:0,
      limit: maxPage * pageSize
    });

    if (data.status) {

      console.log("total keys - "+data.keys.length)

      var dummy = [];
      data.keys.map((d, i) => {
        dummy.push({
          sr_no: i + 1,
          //id: d.id,
          key: d.key,
          product_id: d.productId,
          product_name: productName(d.productId),
          valid: d.valid,
          status: keyStatusText(d),
          enable: data.enable ? "Yes" : "No",
          name: d.name,
          phone: d.phone,
          email: d.email,
          place: d.place,
          country: d.country,
          activate_at: d.activateAt
            ? format(new Date(d.activateAt), "dd/MM/yyyy H:mma")
            : "",
          expire_at: d.expireAt
            ? format(new Date(d.expireAt), "dd/MM/yyyy H:mma")
            : "",
            note: data.note ?? "",
          //created_at: format(new Date(d.createdAt), "dd/MM/yyyy H:mma"),
        });
      });

    // var dummy = [];
    // keys.map((data, i) => {
    //   dummy.push({
    //     sr_no: i + 1,
    //     id: data.id,
    //     key: data.key,
    //     product_id: data.productId,
    //     product_name: productName(data.productId),
    //     valid: data.valid,
    //     status: keyStatusText(data),
    //     enable: data.enable ? "Yes" : "No",
    //     name: data.name,
    //     phone: data.phone,
    //     email: data.email,
    //     place: data.place,
    //     country: data.country,
    //     activate_at: data.activateAt
    //       ? format(new Date(data.activateAt), "dd/MM/yyyy H:mma")
    //       : "",
    //     expire_at: data.expireAt
    //       ? format(new Date(data.expireAt), "dd/MM/yyyy H:mma")
    //       : "",
    //     note: data.note ?? "",
    //     created_at: format(new Date(data.createdAt), "dd/MM/yyyy H:mma"),
    //   });
    // });

    setXlsData(dummy);
    }
  };

  function productName(id) {
    if (id) {
      const productIndex = products.findIndex((x) => x._id === id);

      if (productIndex != -1) {
        return products[productIndex].name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  function keyStatus(d) {
    if (d.status == 0) {
      return <Badge variant="success light">Available</Badge>;
    } else if (d.status == 1) {
      return <Badge variant="secondary">Active</Badge>;
    } else if (d.status == 2) {
      return <Badge variant="danger light">Deactive</Badge>;
    } else if (d.status == 3) {
      return <Badge variant="danger light">Expire</Badge>;
    } else {
      return <></>;
    }
  }

  function keyStatusText(d) {
    if (d.status == 0) {
      return "Available";
    } else if (d.status == 1) {
      return "Active";
    } else if (d.status == 2) {
      return "Deactive";
    } else if (d.status == 3) {
      return "Expire";
    } else {
      return "";
    }
  }

  const onGenerate = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (selectedProduct === "") {
      error = true;
      errorObj.product = "Product is Required";
    }

    if (totalKeys == "") {
      error = true;
      errorObj.totalKeys = "Total keys is Required";
    }else if (Number(totalKeys) > 10 ) {
      error = true;
      errorObj.totalKeys = "Total keys must be less than 10";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post(
          "reseller/generate-trial-key",
          {
            name: name,
            phone: phone,
            total_keys:Number(totalKeys),
            product_id: selectedProduct,
            note: note,
          }
        );

        if (data.status === false) {
          swal("Generate Trial license", data.message, "error");
        } else {
          setGenerateKeyModal(false);
          swal("Generate Trial license", data.message, "success");

          setName("");
          setPhone("");
          setNote("");
          setTotalKeys(1);

          setTimeout(() => {
            loadKeys();
          }, 500);
        }
      } catch (error) {}
    }
  };

  const getLicPer = (value) => {

    return (statistics.products
      ? (value *
          100) /
          statistics.products.find(
            (x) => x.code == match.params.product
          ).trialKeys ?? 0
      : 0)

  };

  return (
    <>
      <Modal
        className="modal fade"
        show={generateKeyModal}
        onHide={setGenerateKeyModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onGenerate}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Generate Trial Key</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setGenerateKeyModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500"> Products</label>
                      <div className="contact-name">
                        <select
                          className="form-control"
                          value={selectedProduct}
                          onChange={(e) => setSelectedProduct(e.target.value)}
                        >
                          <option value="select">Choose Product</option>
                          {products.filter((x)=>x.enable).map((p, i) => {
                            return (
                              <option key={i} value={p._id}>
                                {i + 1} - {p.name}
                              </option>
                            );
                          })}
                        </select>
                        {errors.product && (
                          <div className="text-danger fs-12">
                            {errors.product}
                          </div>
                        )}
                      </div>
                    </div>


                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Total Keys</label>
                      <div className="contact-name">
                      <input
                          type="number"
                          value={totalKeys}
                          className="form-control"
                          autocomplete="off"
                          min={1}
                          max={10}
                          required="required"
                          onChange={(e) => setTotalKeys(e.target.value)}
                          placeholder="Total Keys"
                        />
                        {errors.totalKeys && (
                          <div className="text-danger fs-12">{errors.totalKeys}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>


                    {/* <div className="form-group mb-3">
                      <label className="text-black font-w500">Name (optional)</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          value={name}
                          className="form-control"
                          autocomplete="off"
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                        />
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div> */}

                    {/* <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Phone Number (optional)
                      </label>
                      <div className="contact-name">
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={phone}
                          defaultCountry="IN"
                          onChange={setPhone}
                        />

                        {errors.phone && (
                          <div className="text-danger fs-12">{errors.phone}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div> */}

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Note (optional)
                      </label>
                      <div className="contact-name">
                        <textarea
                          type="text"
                          value={note}
                          rows={4}
                          className="form-control"
                          autocomplete="off"
                          onChange={(e) => setNote(e.target.value)}
                          placeholder="Note"
                        />

                        <span className="validation-text"></span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setGenerateKeyModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Generate
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Fragment>
        {/* <div className="row">
        

          <div className="col-xl-3 col-md-6">
            <div
              className={
                "widget-stat card " +
                (filter == "trial" ? "bg-selected-card" : "")
              }
              onClick={(e) => setFilter("trial")}
              style={{ cursor: "pointer" }}
            >
              <div className="card-body p-4">
                <h4 className="card-title">Trial Keys</h4>
                <h3>
                  {statistics.products
                    ? statistics.products.find(
                        (x) => x.code == match.params.product
                      ).trialKeys ?? 0
                    : 0}
                </h3>
                <div className="progress mb-2">
                  <div
                    className="progress-bar progress-animated bg-success"
                    style={{
                      width: "100%",
                    }}
                  ></div>
                </div>

                <small>100% of License Keys</small>
              </div>
            </div>
          </div>

        </div> */}


<div className="row">
          <div className="col-xl-3 col-md-6">
            <div
              className={
                "widget-stat card " +
                (filter == "all" ? "bg-selected-card" : "")
              }
              onClick={(e) => setFilter("all")}
              style={{ cursor: "pointer" }}
            >
              <div className="card-body p-4">
                <h4 className="card-title">Total Keys</h4>
                <h3>
                  {statistics.products
                    ? statistics.products.find(
                        (x) => x.code == match.params.product
                      ).trialKeys ?? 0
                    : 0}
                </h3>
                <div className="progress mb-2">
                  <div
                    className="progress-bar progress-animated bg-primary"
                    style={{ width: 100 + "%" }}
                  ></div>
                </div>
                <small>100% of License Keys</small>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6">
            <div
              className={
                "widget-stat card " +
                (filter == "available" ? "bg-selected-card" : "")
              }
              onClick={(e) => setFilter("available")}
              style={{ cursor: "pointer" }}
            >
              <div className="card-body p-4">
                <h4 className="card-title">Available Keys</h4>
                <h3>
                  {statistics.products
                    ? statistics.products.find(
                        (x) => x.code == match.params.product
                      ).trialAvailableKeys ?? 0
                    : 0}
                </h3>
                <div className="progress mb-2">
                  <div
                    className="progress-bar progress-animated bg-success"
                    style={{
                      width:
                      getLicPer(statistics.products
                        ? statistics.products.find(
                            (x) => x.code == match.params.product
                          ).trialAvailableKeys ?? 0
                        : 0)+ "%",
                    }}
                  ></div>
                </div>

                <small>{getLicPer(statistics.products
                        ? statistics.products.find(
                            (x) => x.code == match.params.product
                          ).trialAvailableKeys ?? 0
                        : 0).toFixed(2)}% of License Keys</small>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6">
            <div
              className={
                "widget-stat card " +
                (filter == "active" ? "bg-selected-card" : "")
              }
              onClick={(e) => setFilter("active")}
              style={{ cursor: "pointer" }}
            >
              <div className="card-body p-4">
                <h4 className="card-title ">Active Keys</h4>
                <h3>
                  {statistics.products
                    ? statistics.products.find(
                        (x) => x.code == match.params.product
                      ).trialActiveKeys ?? 0
                    : 0}
                </h3>
                <div className="progress mb-2">
                  <div
                    className="progress-bar progress-animated bg-warning"
                    style={{ width:  getLicPer(statistics.products
                      ? statistics.products.find(
                          (x) => x.code == match.params.product
                        ).trialActiveKeys ?? 0
                      : 0)+"%" }}
                  ></div>
                </div>
                <small>{getLicPer(statistics.products
                        ? statistics.products.find(
                            (x) => x.code == match.params.product
                          ).trialActiveKeys ?? 0
                        : 0).toFixed(2)}% of License Keys</small>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6">
            <div
              className={
                "widget-stat card " +
                (filter == "expired" ? "bg-selected-card" : "")
              }
              onClick={(e) => setFilter("expired")}
              style={{ cursor: "pointer" }}
            >
              <div className="card-body p-4">
                <h4 className="card-title">Expired Keys</h4>
                <h3>
                  {statistics.products
                    ? statistics.products.find(
                        (x) => x.code == match.params.product
                      ).trialExpireKeys ?? 0
                    : 0}
                </h3>
                <div className="progress mb-2">
                  <div
                    className="progress-bar progress-animated bg-red"
                    style={{ width: getLicPer(statistics.products
                      ? statistics.products.find(
                          (x) => x.code == match.params.product
                        ).trialExpireKeys ?? 0
                      : 0)+"%" }}
                  ></div>
                </div>
                <small>{getLicPer(statistics.products
                        ? statistics.products.find(
                            (x) => x.code == match.params.product
                          ).trialExpireKeys ?? 0
                        : 0).toFixed(2)}% of License Keys</small>
              </div>
            </div>
          </div>
        </div>


        <div className="card">
          <div className="card-header">
            <h4 className="card-title mr-auto">{statistics.products
                    ? statistics.products.find(
                        (x) => x.code == match.params.product
                      ).trialKeys ?? 0
                    : 0} Trial Licenses Keys</h4>

            <div className="input-group search-area d-lg-inline-flex d-none mr-2">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (searchTimeout) {
                    clearTimeout(searchTimeout);
                  }
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </form>
              <div className="input-group-append">
                <span className="input-group-text">
                  <svg
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                      fill="#A4A4A4"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <Button
              className="btn btn btn-sm btn-primary mr-2"
              onClick={() => setGenerateKeyModal(true)}
            >
              Generate Trial Key
            </Button>

            {xlsData.length > 0 ?<CSVLink
              ref={csvInstance}
              data={xlsData}
              filename={match.params.product + "-trial-keys" + ".csv"}
              className="btn btn-sm btn-primary mr-2"
              target="_blank"
              // asyncOnClick={true}
              // onClick={async (event, done) => {
              //   console.log("You click the link");
              //   await initXlsData();
              //   done(); // 👍🏻 You are stopping the handling of component
              // }}
            >
              Export
            </CSVLink>: (
                <Button
                  className="btn btn-sm btn-primary mr-2"
                  onClick={() => initXlsData()}
                >
                  Export
                </Button>
              )}
          </div>
          <div className="card-body">
            {loading ? (
              <div className="text-center mt-4">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : keys.length == 0 ? (
              <p>No Trial License Keys Found</p>
            ) :(
              <DTable
              columns={columns}
              data={keys}
              pageSize={pageSize}
              isSortable={true}
              pagination={false}
              pageSizes={pageSizes}
              page={page}
              maxPage={maxPage}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
            />
             /*  <Table responsive className="w-100">
                <div id="example_wrapper" className="dataTables_wrapper">
                  <table id="example" className="display w-100 dataTable">
                    <thead>
                      <tr role="row">
                        <th>Sr No</th>
                        <th>Key</th>
                        <th>User</th>
                        <th>Valid</th>
                        <th>Status</th>
                        <th>Enable</th>
                        <th>Active</th>
                        <th>Expire</th>
                        <th>Note</th>
                        <th>Created</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((d, i) => (
                        <tr key={i}>
                           <td>{(activePage * sort + 1)+i}</td>
                          <td
                            style={{ whiteSpace: "nowrap" }}
                            onClick={() => {
                              navigator.clipboard.writeText(d.key);
                            }}
                          >
                            {d.key}
                          </td>
                          {d.status != 0 || d.trial ? (
                            <td>
                              {d.name}
                              <br />
                              <small>{d.phone}</small>
                              <br />
                              <small>{d.email}</small>
                              <br />
                              <small>{d.place}</small>
                              <br />
                              <small>{d.country}</small>
                            </td>
                          ) : (
                            <td></td>
                          )}

                          <td>
                            {d.valid} {d.valid == 1 ? "Day" : "Days"}
                          </td>
                          <td className="text-center">{keyStatus(d)}</td>

                          <td className="text-center">
                            {d.enable ? (
                              <Badge variant="success light">Yes</Badge>
                            ) : (
                              <Badge variant="danger light">No</Badge>
                            )}
                          </td>

                          <td>
                            {d.activateAt
                              ? format(
                                  new Date(d.activateAt),
                                  "dd/MM/yyyy H:mma"
                                )
                              : "No"}{" "}
                          </td>

                          <td>
                            {d.expireAt
                              ? format(new Date(d.expireAt), "dd/MM/yyyy H:mma")
                              : "No"}{" "}
                          </td>

                          <td>{d.note ?? ""}</td>

                          <td>
                            {d.createdAt
                              ? format(
                                  new Date(d.createdAt),
                                  "dd/MM/yyyy H:mma"
                                )
                              : "No"}{" "}
                          </td>

                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant=""
                                className="table-dropdown icon-false"
                              >
                                <svg
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect
                                      x="0"
                                      y="0"
                                      width="24"
                                      height="24"
                                    ></rect>
                                    <circle
                                      fill="#000000"
                                      cx="5"
                                      cy="12"
                                      r="2"
                                    ></circle>
                                    <circle
                                      fill="#000000"
                                      cx="12"
                                      cy="12"
                                      r="2"
                                    ></circle>
                                    <circle
                                      fill="#000000"
                                      cx="19"
                                      cy="12"
                                      r="2"
                                    ></circle>
                                  </g>
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {d.enable ? (
                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => onChangeLicStatus(d, false)}
                                  >
                                    Disable
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => onChangeLicStatus(d, true)}
                                  >
                                    Enable
                                  </Dropdown.Item>
                                )}

                                {d.status == 1 ? (
                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => onRemoveDevice(d)}
                                  >
                                    Remove Device
                                  </Dropdown.Item>
                                ) : (
                                  <></>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr role="row">
                        <th>Sr No</th>
                        <th>Key</th>
                        <th>User</th>

                        <th>Valid</th>
                        <th>Status</th>
                        <th>Enable</th>
                        <th>Active</th>
                        <th>Expire</th>
                        <th>Note</th>
                        <th>Created</th>
                        <th>Action</th>
                      </tr>
                    </tfoot>
                  </table>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      Showing {activePage * sort + 1} to &nbsp;
                      {keys.length < (activePage + 1) * sort
                        ? keys.length
                        : (activePage + 1) * sort}
                      &nbsp;of {keys.length} entries
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers">
                      <Pagination
                        className="pagination-primary pagination-circle"
                        size="lg"
                      >
                        <li
                          className="page-item page-indicator "
                          onClick={() =>
                            activePage > 1 && onClick(activePage - 1)
                          }
                        >
                          <Link className="page-link" to="#">
                            <i className="la la-angle-left" />
                          </Link>
                        </li>
                        {pagination.map((number, i) => (
                          <Pagination.Item
                            key={"page-" + i}
                            className={activePage === i ? "active" : ""}
                            onClick={() => onClick(i)}
                          >
                            {number}
                          </Pagination.Item>
                        ))}
                        <li
                          className="page-item page-indicator"
                          onClick={() =>
                            activePage + 1 < pagination.length &&
                            onClick(activePage + 1)
                          }
                        >
                          <Link className="page-link" to="#">
                            <i className="la la-angle-right" />
                          </Link>
                        </li>
                      </Pagination>
                    </div>
                  </div>
                </div>
              </Table> */
            )}
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default LicenseKeys;
