import React, { Fragment, useState,useEffect } from "react";
import { Link } from "react-router-dom";

import { Dropdown, Button, Modal } from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";

import { SRLWrapper } from "simple-react-lightbox";

import { useDispatch,useSelector } from "react-redux";

import axiosInstance from '../../../services/AxiosInstance';

import swal from "sweetalert";

const MyProfile = () => {
   const [activeToggle, setActiveToggle] = useState("posts");
   const [sendMessage, setSendMessage] = useState(false);
   const [replay, setReplay] = useState(false);

   const dispatch = useDispatch();

   let errorsObj = { name: '',addLine1:"",addLine2:"",addCity:"", addState:"",addLandmark:"",addZipcode:""};
   const [errors, setErrors] = useState(errorsObj);
   
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [addLine1, setAddLine1] = useState("");
   const [addLine2, setAddLine2] = useState("");
   const [addCity, setAddCity] = useState("");
   const [addState, setAddState] = useState("");
   const [addLandmark, setAddLandmark] = useState("");
   const [addZipcode, setAddZipcode] = useState("");
   const [addCountry, setAddCountry] = useState("India");

   //const [profile, setProfile] = useState({});

   const options = {
      settings: {
         overlayColor: "#000000",
      },
   };

   useEffect(() => {
		loadDetails();
	  }, [dispatch]);

   const loadDetails = async () => {
      const { data } = await axiosInstance.get(
         "auth/profile"
       );
 
       if(data.profile){
         //setProfile(data.user);
         const profile = data.profile;
         setName(profile.name??"");
         setEmail(profile.email??"")
       
         if(profile.address){
            setAddLine1(profile.address.addressLine1??"");
            setAddLine2(profile.address.addressLine2??"");
            setAddCity(profile.address.city??"");
            setAddState(profile.address.state??"");
            setAddZipcode(profile.address.zip??"");
         }
       }
 
      console.log("details data - "+JSON.stringify(data));
   }

   const onSaveProfile = async (e) => {
      e.preventDefault();
      let error = false;
      const errorObj = { ...errorsObj };


   if (name === '') {
          errorObj.name = 'Name is Required';
          error = true;
   }

   
   if (email === '') {
      errorObj.email = 'Email is Required';
      error = true;
}
    
    if (addLine1 === '') {
      errorObj.addLine1 = 'Address Line 1 is Required';
      error = true;
   }
   
    if (addCity === '') {
         errorObj.addCity = 'Address City is Required';
         error = true;
   }
    if (addState === '') {
      errorObj.addState = 'Address State is Required';
      error = true;
   }

   if (addCountry === '') {
      errorObj.addCouny = 'Address Country is Required';
      error = true;
   }

    if (addZipcode === '') {
         errorObj.addZipcode = 'Address Zip Code is Required';
         error = true;
   }
      setErrors(errorObj);
      if (error) {
         console.log("error on save");
       return;
      }
      console.log("no error on save");
      //save data

      const formData =  {
         name:name,
         email:email,
         add_line_1:addLine1,
         add_line_2:addLine2,
         add_city:addCity,
         add_state:addState,
         add_zipcode:addZipcode
      };

      console.log("form data - "+formData)

      const { data } = await axiosInstance.post(
         "reseller/update-details",
         formData
       );

       if(data.status){
         swal('Update Profile', data.message,"success");
       }else{
         swal('Update Profile', data.message,"error");
       }

   }

   return (
      <Fragment>
         <PageTitle activeMenu="My Profile" motherMenu="Dashboard" />

        
         <div className="row">
          
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                     
                             
                              <div
                                 id=""
                               
                              >
                                 <div className="pt-3">
                                    <div className="settings-form">
                                       <h4 className="text-primary">
                                          Inforamation
                                       </h4>
                                       <form
                                          onSubmit={onSaveProfile}
                                       >
                                          <div className="form-row">
                                             <div className="form-group col-md-6">
                                                <label>Name</label>
                                                <input
                                                   type="text"
                                                   value={name}
                                                   onChange={(e) =>
                                                      setName(e.target.value)
                                                   }
                                                   placeholder="Name"
                                                   className="form-control"
                        
                                                />
                                                {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                                             </div>
                                             <div className="form-group col-md-6">
                                                <label>Email</label>
                                                <input
                                                   type="email"
                                                   value={email}
                                                   onChange={(e) =>
                                                      setEmail(e.target.value)
                                                   }
                                                   placeholder="Email"
                                                   className="form-control"
                        
                                                />
                                                {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                             </div>
                                          </div>

                                        

                                          <div className="form-group">
                                             <label>Address Line 1</label>
                                             <input
                                                type="text"
                                                value={addLine1}
                                                   onChange={(e) =>
                                                      setAddLine1(e.target.value)
                                                   }
                                                placeholder="e.g. 1234 Main St"
                                                className="form-control"
                                             />
                                             {errors.addLine1 && <div className="text-danger fs-12">{errors.addLine1}</div>}
                                          </div>
                                          <div className="form-group">
                                             <label>Address Line 2</label>
                                             <input
                                                type="text"
                                                value={addLine2}
                                                onChange={(e) =>
                                                   setAddLine2(e.target.value)
                                                }
                                                placeholder="e.g. Apartment, studio, or floor"
                                                className="form-control"
                                             />
                                             {errors.addLine2 && <div className="text-danger fs-12">{errors.addLine2}</div>}
                                          </div>
                                          <div className="form-row">
                                             <div className="form-group col-md-3">
                                                <label>City</label>
                                                <input
                                                 value={addCity}
                                                 onChange={(e) =>
                                                    setAddCity(e.target.value)
                                                 }
                                                   type="text"
                                                   className="form-control"
                                                />
                                                {errors.addCity && <div className="text-danger fs-12">{errors.addCity}</div>}
                                             </div>
                                            
                                             <div className="form-group col-md-3">
                                                <label>State</label>
                                                
                                                <input
                                                 value={addState}
                                                 onChange={(e) =>
                                                    setAddState(e.target.value)
                                                 }
                                                   type="text"
                                                   className="form-control"
                                                />


                                                {errors.addState && <div className="text-danger fs-12">{errors.addState}</div>}
                                             </div>


                                             <div className="form-group col-md-3">
                                                <label>Country</label>
                                                
                                                <input
                                                 value={addCountry}
                                                 onChange={(e) =>
                                                    setAddCountry(e.target.value)
                                                 }
                                                   type="text"
                                                   className="form-control"
                                                />


                                                {errors.addContry && <div className="text-danger fs-12">{errors.addCountry}</div>}
                                             </div>

                                             <div className="form-group col-md-3">
                                                <label>Zip Code</label>
                                                <input
                                                   type="text"
                                                   value={addZipcode}
                                                   onChange={(e) =>
                                                      setAddZipcode(e.target.value)
                                                   }
                                                   className="form-control"
                                                />
                                                {errors.addZipcode && <div className="text-danger fs-12">{errors.addZipcode}</div>}
                                             </div>
                                          </div>
                                         
                                          <button
                                             className="btn btn-primary"
                                             type="submit"
                                          >
                                             Save
                                          </button>
                                       </form>
                                    </div>
                                 </div>
                              </div>
                          
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default MyProfile;
