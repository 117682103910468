import axiosInstance from '../services/AxiosInstance';

export function getInvitations(filter) {
    return axiosInstance.post(`agent/members`,filter);
}

export function inviteMember(postData) {
    return axiosInstance.post(`agent/invite`, postData);
}

export function bulkInviteMember(postData) {
    return axiosInstance.post(`agent/bulk-invite`, postData);
}

export function cancelInvitation(postData) {
    return axiosInstance.post(`agent/cancel-invite`, postData);
}

export function formatInvitations(inviteData) {
    let invitations = [];
    for (let key in inviteData) {
        invitations.push({ ...inviteData[key], id: key });
    }

    return invitations;
}
