import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
  loginAction2,
  loginFailedAction,
} from "../../store/actions/AuthActions";

import { useAuth, auth, firebase } from "../../providers/use-auth";
import swal from "sweetalert";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Spinner } from "react-bootstrap";
import loginbg from "../../images/background.svg";

import axiosInstance from '../../services/AxiosInstance';

function Login(props) {
 
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
 

  const [phone, setPhone] = useState("");
  const [otp, setotp] = useState("");
  const [showOtp, setshowOtp] = useState(false);
  
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(async () => {
    dispatch(loginFailedAction(null));
  }, [dispatch]);

 
  // Sent OTP
  const signin = async (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (phone === "" || phone.length < 10) {
      errorObj.phone = "Phone Number is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    setLoading(true);


    const { data } = await axiosInstance.post(
      "reseller/send-otp",{
        "auth_type":"phone",
        "phone":phone,
        "master_reseller_id":process.env.REACT_APP_MASTER_RESELLER_ID
      }
    );

    setLoading(false);

    if(data.status){
      swal(
        "Phone Login",
        "One Time Password sent on your WhatsApp " + phone,
        "success"
      );
      setshowOtp(true);
    }else{
      swal("Phone Login", data.message, "error");
    }

  

  };

  // Validate OTP
  const ValidateOtp = async (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (otp === null) {
      errorObj.phone = "OTP is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    setLoading(true);



    const response = await axiosInstance.post(
      "reseller/verify-otp",{
        "auth_type":"phone",
        "phone":phone,
        "otp":otp
      }
    );

    setLoading(false);

      dispatch(
        loginAction2(
          response,
          props.history
        )
      );
    
  };

  return (
    <div
      className="login-main-page"
      style={{ backgroundImage: "url(" + loginbg + ")" }}
    >
      <div className="login-wrapper">
        <div className="login-aside-left">
          <div className="login-description">

            <div className="img-shadow" >
            <img
              class="img img-fluid mt-0 image-shadow"
              width={350}
              src={process.env.REACT_APP_LOGO}
              alt=""
            />
            </div>
           

            <h2 className="main-title mb-2 mt-5">Welcome To {process.env.REACT_APP_NAME}</h2>
            <p className=""></p>
            <ul className="social-icons mt-4">
              <li>
                <a href={process.env.REACT_APP_URL}>
                  <i className="fa fa-globe"></i>
                </a>
              </li>
            </ul>
            <div className="mt-3 bottom-privacy">
              <p>
                Copyright © Designed & Developed by{" "}
                <a
                  href={process.env.REACT_APP_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {process.env.REACT_APP_BY}
                </a>{" "}
                2023
              </p>
            </div>
          </div>
        </div>
        <div className="login-aside-right">
          <div className="row m-0 justify-content-center h-100 align-items-center">
            <div className="p-5">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form-1">
                      <div className="mb-4">
                        <h3 className="dz-title mb-1">Sign in</h3>
                        <p className="">
                          By entering information below
                        </p>
                      </div>
                      {props.errorMessage && (
                        <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                          {props.errorMessage}
                        </div>
                      )}
                      {props.successMessage && (
                        <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                          {props.successMessage}
                        </div>
                      )}

                      {!showOtp ? (
                        <form onSubmit={signin} className="mt-4">
                          <div className="form-group">
                            <label className="mb-2 ">
                              <strong>Phone Number</strong>
                            </label>

                            <PhoneInput
                              placeholder="Enter phone number"
                              value={phone}
                              defaultCountry="IN"
                              onChange={setPhone}
                            />

                            {errors.phone && (
                              <div className="text-danger fs-12">
                                {errors.phone}
                              </div>
                            )}
                          </div>


                          {process.env.REACT_APP_SHOW_TERMS === "true" ? (
                      <small>
                        I have read and agree to the{" "}
                        <a
                          className="text-primary"
                          target="_blank"
                          href="https://digibulkmarketing.com/privacy-policy/"
                        >
                          Privacy Policy
                        </a>
                        . Our{" "}
                        <a
                          className="text-primary"
                          target="_blank"
                          href="https://digibulkmarketing.com/terms-conditions/"
                        >
                          Terms &amp; Conditions
                        </a>{" "}
                        apply.
                      </small>
                    ) : (
                      <></>
                    )}


                          <div className="text-center mt-4">
                            {loading ? (
                              <Spinner animation="border" variant="primary" />
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary btn-block"
                              >
                                Send OTP
                              </button>
                            )}
                          </div>

                          {props.errorMessage && (
                            <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2 text-danger">
                              {props.errorMessage}
                            </div>
                          )}
                          {props.successMessage && (
                            <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                              {props.successMessage}
                            </div>
                          )}
                        </form>
                      ) : (
                        <form onSubmit={ValidateOtp} className="">
                          <div className="form-group">
                            <Link
                              className="text-primary"
                              onClick={(e) => {
                                
                                setshowOtp(false);
                              }}
                            >
                              Change Number
                            </Link>

                            <br />

                            <label className="my-2">
                              <strong>One Time Password</strong>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={otp}
                              onChange={(e) => setotp(e.target.value)}
                              placeholder="Enter OTP"
                            />

                            {errors.otp && (
                              <div className="text-danger fs-12">
                                {errors.otp}
                              </div>
                            )}
                          </div>

                          <div className="text-center mt-4">
                            {loading ? (
                              <Spinner animation="border" variant="primary" />
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary btn-block"
                              >
                                Verify
                              </button>
                            )}
                          </div>

                          {props.errorMessage && (
                            <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2 text-danger">
                              {props.errorMessage}
                            </div>
                          )}

                          {props.successMessage && (
                            <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                              {props.successMessage}
                            </div>
                          )}
                        </form>
                      )}

                      <div id="recaptcha-container"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
