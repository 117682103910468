import React, { useState, useEffect,Fragment } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import xlsx from "xlsx";
import Moment from "moment";
import { format } from "date-fns";

import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CSVLink } from "react-csv";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  Modal,
  ProgressBar,
  Button,
  Spinner,
} from "react-bootstrap";

import {
    getProfile,
 } from '../../../store/actions/AuthActions';

 import axiosInstance from '../../../services/AxiosInstance';
 

const RedeemVoucher = ({ props, history }) => {
  const dispatch = useDispatch();

  const [code, setCode] = useState("");
  const [errors, setErrors] = useState({message:""});

  const profile = useSelector((state) => state.auth.profile);

  const [loading, setLoading] = useState(false);
  const [vouchers, setVouchers] = useState([]);

  useEffect(() => {
    dispatch(getProfile());
    loadVouchers();
}, [dispatch]);


const loadVouchers = async () => {
  const { data } = await axiosInstance.post("reseller/voucher-codes", {
    search:""
  });

  if (data.status) {
    setVouchers(data.vouchers);
  }
};


  const onSubmit = async (event)=> {
    event.preventDefault();  

    try {
			
        const { data } = await axiosInstance.post(
           "reseller/redeem-voucher",
           { code:code }
        );


    if(data.status === false){
       //toast.error(data.message);
     swal('Redeem Voucher', data.message,"error");

    }else{
       //toast.success(data.message);
     swal('Redeem Voucher', data.message, "success");
     setCode("");

    }
     }catch (error) {
        swal('Redeem Voucher', error,"error");
     }

  }

  return (
    <>

        <Fragment>
        <div className="col-12">
          <Card>
            <Card.Header>
              <Card.Title className="mr-auto p-2">Redeem Voucher</Card.Title>
            </Card.Header>
            <Card.Body>

            <form onSubmit={onSubmit}>


            <div className="form-row">
                                             <div className="form-group col-md-6">
                                                
                                                <input value={code}  className="form-control"  autocomplete="off"
														name="message" required="required"
                                          onChange={(e) =>
                                             setCode(e.target.value)
                                          }
														placeholder="Enter Voucher Code"
													/>
                                                {errors.code && <div className="text-danger fs-12">{errors.code}</div>}
                                         </div>
                                             <div className="form-group col-md-6">
                                               
                                             </div>
                                          </div>

								
                                          <button
                                             className="btn btn-primary"
                                             type="submit"
                                          >
                                             Redeem
                                          </button>
								
                                     
								
							</form>


            </Card.Body>
          </Card>
          </div>
      </Fragment>


      <Fragment>
        {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mr-auto">Redeem History</h4>

            </div>
            <div className="card-body">
              {loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : vouchers.length == 0 ? (
                <p>No Redeem History Found</p>
              ) : (
                <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <table id="example" className="display w-100 dataTable">
                      <thead>
                        <tr role="row">
                          <th>Sr No</th>
                          <th>Code</th>
                          <th>Product</th>
                          <th>Keys</th>
                          <th>Valid</th>
                          <th>Redeem At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vouchers.map((d, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td style={{whiteSpace:"nowrap"}} onClick={() => {navigator.clipboard.writeText(d.code)}}>{d.code}</td>
                            <td>{d.productId.name}</td>
                            <td>{d.keys} Keys</td>
                            <td>{d.valid} Days</td>
                        
                            <td>
                              {d.redeemAt
                                ? format(
                                    new Date(d.redeemAt),
                                    "dd/MM/yyyy H:mma"
                                  )
                                : "No"}{" "}
                            </td>

                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr role="row">
                          <th>Sr No</th>
                          <th>Code</th>
                          <th>Product</th>
                          <th>Keys</th>
                          <th>Valid</th>
                          <th>Redeem At</th>
                        </tr>
                      </tfoot>
                    </table>
                   
                  </div>
                </Table>
              )}
            </div>
          </div>
        </div>
      </Fragment>

    </>
  );
};

export default RedeemVoucher;
