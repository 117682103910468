import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Dropdown,
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  FormControl,
  Card,
} from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";

import { SRLWrapper } from "simple-react-lightbox";

import { useDispatch, useSelector } from "react-redux";

import axiosInstance from "../../../services/AxiosInstance";

import swal from "sweetalert";

import FormData from "form-data";

import noIamage from "../../../images/no-image-uploaded.png";

import ColorPicker1 from "material-ui-color-picker";

import { TwitterPicker, SketchPicker, SliderPicker } from "react-color";
import { getAllProducts } from "../../../store/actions/licActions";

const Setting = ({ match }) => {
  const [activeToggle, setActiveToggle] = useState("posts");
  const [sendMessage, setSendMessage] = useState(false);
  const [replay, setReplay] = useState(false);

  const profile = useSelector((state) => state.auth.profile);

  const products = useSelector((state) => state.lic.products);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const dispatch = useDispatch();

  let errorsObj = {
    name: "",
    description: "",
    androidUpdate: "",
    iosUpdate: "",
    androidVersion: "",
    iosVersion: "",
    appStoreUrl: "",
    playStoreUrl: "",
    newsUpdate: "",
    frontImage: "",
    frontLink: "",
    backImage: "",
    backLink: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [name, setName] = useState("");
  const [logo, setLogo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [description, setDescription] = useState("");

  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");

  const [siteLink, setSiteLink] = useState("");
  const [demoVideoLink, setDemoVideoLink] = useState("");

  const [showAd, setShowAd] = useState(false);
  const [adBannerLink, setBannerAdLink] = useState("");
  const [adLink, setAdLink] = useState("");

  const [updateUrl, setUpdateUrl] = useState("");

  const [rebrand, setRebrand] = useState(false);
  const [rebrandProducts, setRebrandProducts] = useState([]);

  const [version, setVersion] = useState(1);

  const [color, setColor] = useState({ hex: "#08094e" });

  const options = {
    settings: {
      overlayColor: "#000000",
    },
  };

  useEffect(() => {
    //loadDetails();
    loadProfile();
  }, [dispatch]);

  useEffect(() => {
    loadDetails(match.params.product);
    setTitle();
  }, [match]);

  const setTitle = () => {
    if (products.length) {
      var product = products.find((x) => x.code == match.params.product);

      setSelectedProduct(product);
      console.log("product - " + JSON.stringify(product));
      //document.getElementById("nav_title").innerHTML = product.name??"";
      const div = document.getElementById("nav_title");
      if (div) {
        div.innerHTML = (product.name ?? "") + " Rebrand Setting";
      }
    }
  };

  useEffect(() => {
    setTitle();
  }, [products]);

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  const loadProfile = async () => {
    const { data } = await axiosInstance.get("auth/profile");

    if (data.profile) {
      //setProfile(data.user);
      const profile = data.profile;

      if (profile.rebrand) {
        setRebrand(profile.rebrand ?? false);
      }

      if (profile.rebrandProducts) {
        setRebrandProducts(profile.rebrandProducts);
      }
    }

    console.log("details data - " + JSON.stringify(data));
  };

  const loadDetails = async (productId) => {
    const { data } = await axiosInstance.post("reseller/rebrand", {
      product_code: productId,
    });

    if (data.detail) {
      setName(data.detail.name ?? "");
      setDescription(data.detail.description ?? "");

      setContact(data.detail.contactNumber);
      setEmail(data.detail.email);

      setShowAd(data.detail.showAd);
      setBannerAdLink(data.detail.adBannerUrl);
      setAdLink(data.detail.adUrl);

      setSiteLink(data.detail.siteUrl);
      setDemoVideoLink(data.detail.demoVideoUrl);

      setColor({ hex: data.detail.color });

      setUpdateUrl(data.detail.updateUrl);
      setVersion(data.detail.version);
    } else {
      setName("");
      setDescription("");

      setContact("");
      setEmail("");

      setShowAd(false);
      setBannerAdLink("");
      setAdLink("");

      setSiteLink("");
      setDemoVideoLink("");

      //setColor({ hex: data.detail.color });

      setUpdateUrl("");
      setVersion(1);
    }

    console.log("details data - " + JSON.stringify(data));
  };

  const onSaveDetails = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };


    if (logo === "") {
      errorObj.logo = "Software Logo is Required";
      error = true;
    }

    if (name === "") {
      errorObj.name = "Software Name is Required";
      error = true;
    }

    if (companyName === "") {
      errorObj.companyName = "Company Name is Required";
      error = true;
    }

    if (color.hex === "") {
      errorObj.color = "Color Hex Code is Required";
      error = true;
    }

    if (contact === "") {
      errorObj.contact = "Contact Number is Required";
      error = true;
    }

    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }

    if (siteLink === "") {
      errorObj.siteLink = "Website Link is Required";
      error = true;
    } else if (!siteLink.startsWith("http")) {
      errorObj.siteLink = "Enter valid Website Link";
      error = true;
    }

    if (demoVideoLink === "") {
      errorObj.demoVideoLink = "Demo Video Link is Required";
      error = true;
    } else if (!demoVideoLink.startsWith("http")) {
      errorObj.demoVideoLink = "Enter valid Website Link";
      error = true;
    }

    if (showAd) {
      if (adBannerLink === "") {
        errorObj.adBannerLink = "Ad Banner Image is Required";
        error = true;
      }

      if (adLink === "") {
        errorObj.adLink = "Ad Action Link is Required";
        error = true;
      } else if (!adLink.startsWith("http")) {
        errorObj.adLink = "Enter valid Ad Action Link";
        error = true;
      }
    }

    setErrors(errorObj);
    if (error) {
      console.log("error on save");
      return;
    }
    console.log("no error on save");
    //save data

    const formData = {
      product_id: selectedProduct?._id,

      name: name,

      logo:logo,
      company_name:companyName,

      description: "",
      color: color.hex,

      contact_number: contact,
      email: email,

      show_ad: showAd,
      ad_banner_url: adBannerLink,
      ad_url: adLink,

      site_url: siteLink,
      demo_video_url: demoVideoLink,
    };

    console.log("form data - " + formData);

    const { data } = await axiosInstance.post(
      "reseller/save-rebrand",
      formData
    );

    if (data.status) {
      swal("Update Rebrand Setting", data.message, "success");
    } else {
      swal("Update Rebrand Setting", data.message, "error");
    }
  };

  const fileChangedHandler = async (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        //upload image
        var file = event.target.files[0];

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const formData = new FormData();

        formData.append("image", file, file.name);
        formData.append("type", "banner");

        const { data } = await axiosInstance.post("app/upload-image", formData);

        //console.log("image upload response - "+data);
        console.log("image upload response - " + JSON.stringify(data));

        if (data.status) {
          swal("Image Upload", "successfully done", "success");
          setBannerAdLink(data.downloadUrl);
        } else {
          swal("Image Upload", data.message, "error");
        }
      } catch (err) {
        console.log(err);
        //setProfileImage("");
      }
    }
  };

  const fileClickHandler = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const upload = (e) => {
    e.preventDefault();
    document.getElementById("selectFile").click();
  };



  const fileChangedHandler2 = async (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        //upload image
        var file = event.target.files[0];

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const formData = new FormData();

        formData.append("image", file, file.name);
        formData.append("type", "banner");

        const { data } = await axiosInstance.post("app/upload-image", formData);

        //console.log("image upload response - "+data);
        console.log("image upload response - " + JSON.stringify(data));

        if (data.status) {
          swal("Image Upload", "successfully done", "success");
          setLogo(data.downloadUrl);
        } else {
          swal("Image Upload", data.message, "error");
        }
      } catch (err) {
        console.log(err);
        //setProfileImage("");
      }
    }
  };

  const fileClickHandler2 = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const upload2 = (e) => {
    e.preventDefault();
    document.getElementById("selectFile2").click();
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          {(rebrand && rebrandProducts.find((x) => x === selectedProduct?._id)) || (selectedProduct?.freeRebrand??false) === true ? (
            <div className="card">
              <div className="card-body">
                <div id="">
                  <div className="pt-3">
                    <div className="settings-form">
                      <form onSubmit={onSaveDetails}>
                        <div className="form-row">

                        <div className="col-md-2">

                          <Card>

                          

                          <label className="required-field">
                                Software Logo
                              </label>

                          <Card.Img
                          
                          onClick={upload2}
                          variant="top"  src={
                                    logo != "" ? logo : noIamage
                                  } />

<input
                                  id="selectFile2"
                                  type="file"
                                  hidden
                                  onChange={fileChangedHandler2}
                                  onClick={fileClickHandler2}
                                />

<br/>


<Button  onClick={upload2} size="sm" variant="primary">Upload</Button>

                          </Card>


                       


                        </div>

                          <div className="col-md-4">
                           

                            <div className="form-group">
                              <label className="required-field">
                                Software Name
                              </label>
                              <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Name"
                                className="form-control"
                              />
                              {errors.name && (
                                <div className="text-danger fs-12">
                                  {errors.name}
                                </div>
                              )}
                            </div>

                            <div className="form-group">
                              <label className="required-field">
                                Company Name
                              </label>
                              <input
                                type="text"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                placeholder="Company Name"
                                className="form-control"
                              />
                             
                            </div>

                          </div>

                          <div className="form-group col-md-6">
                            <label>Software Color</label>
                          

                            <div className="row">
                              <div className="form-group col-md-6">
                                <TwitterPicker
                                  color={color}
                                  triangle="hide"
                                  onChangeComplete={setColor}
                                />
                              </div>
                              <div className="form-group ml-auto col-md-4">
                                <div
                                  style={{
                                    height: "100px",
                                    background: color.hex,
                                    borderRadius: "6px 6px 6px 6px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "18px",
                                      color: "rgb(255, 255, 255)",
                                      position: "relative",
                                    }}
                                  >
                                    {color.hex}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <SliderPicker
                              color={color}
                              triangle="hide"
                              onChangeComplete={setColor}
                            />

                            {errors.color && (
                              <div className="text-danger fs-12">
                                {errors.color}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>
                              Contact Number <small>(Help &amp; Support)</small>
                            </label>
                            <input
                              type="text"
                              value={contact}
                              onChange={(e) => setContact(e.target.value)}
                              placeholder="Contact Number"
                              className="form-control"
                            />
                            {errors.contact && (
                              <div className="text-danger fs-12">
                                {errors.contact}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-6">
                            <label>
                              Email Address <small>(Help &amp; Support)</small>
                            </label>
                            <input
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Email Address"
                              className="form-control"
                            />
                            {errors.email && (
                              <div className="text-danger fs-12">
                                {errors.email}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Main Site Url</label>
                            <input
                              type="text"
                              value={siteLink}
                              onChange={(e) => setSiteLink(e.target.value)}
                              placeholder="e.g https://"
                              className="form-control"
                            />
                            {errors.siteLink && (
                              <div className="text-danger fs-12">
                                {errors.siteLink}
                              </div>
                            )}
                          </div>

                          <div className="form-group col-md-6">
                            <label>Video Demo Url</label>
                            <input
                              type="text"
                              value={demoVideoLink}
                              onChange={(e) => setDemoVideoLink(e.target.value)}
                              placeholder="e.g https://"
                              className="form-control"
                            />
                            {errors.demoVideoLink && (
                              <div className="text-danger fs-12">
                                {errors.demoVideoLink}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-3">
                            <label>Show Ads?</label>

                            <select
                              className="form-control"
                              value={showAd ? "yes" : "no"}
                              onChange={(e) =>
                                setShowAd(e.target.value == "yes")
                              }
                              defaultValue="yes"
                            >
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>

                          <div className="form-group col-md-9"></div>

                          {/* <div className="form-group col-md-9">
                            <label>Ads Banner Url</label>
                            <input
                              type="text"
                              value={adBannerLink}
                              onChange={(e) => setBannerAdLink(e.target.value)}
                              placeholder="e.g https://"
                              className="form-control"
                            />
                           
                          </div> */}

                          <div className="col-md-3">
                            <div className="text-center">
                              <div
                                className="card"
                                style={{ background: "#F5F5F5" }}
                              >
                                <img
                                  onClick={upload}
                                  className="img img-fluid"
                                  style={{ cursor: "pointer" }}
                                  src={
                                    adBannerLink != "" ? adBannerLink : noIamage
                                  }
                                ></img>
                                <input
                                  id="selectFile"
                                  type="file"
                                  hidden
                                  onChange={fileChangedHandler}
                                  onClick={fileClickHandler}
                                />

                                <div className="card-footer">
                                  <Button
                                    onClick={upload}
                                    className="btn btn-sm btn-success"
                                  >
                                    Upload
                                  </Button>

                                  {errors.adBannerLink && (
                                    <div className="text-danger fs-12 mt-2">
                                      {errors.adBannerLink}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-9">
                            <div className="form-group">
                              <label>Ads Action Url</label>
                              <input
                                type="text"
                                value={adLink}
                                onChange={(e) => setAdLink(e.target.value)}
                                placeholder="e.g https://"
                                className="form-control"
                              />
                              {errors.adLink && (
                                <div className="text-danger fs-12">
                                  {errors.adLink}
                                </div>
                              )}
                            </div>

                            <div className="form-row">
                              <div className="form-group col-md-3">
                                <label>Version</label>
                                <input
                                  type="number"
                                  value={version}
                                  onChange={(e) => setVersion(e.target.value)}
                                  placeholder="Software Version"
                                  className="form-control"
                                  readOnly
                                  disabled
                                />
                              </div>

                              <div className="form-group col-md-9">
                                <label>Software Download Link</label>
                              

                                <InputGroup className="mb-3">
                                 
                                  <a
                                    className="btn btn-primary"
                                    variant="primary"
                                    href={updateUrl}
                                    target="_blank"
                                    style={{ borderRadius: "0px 5px 5px 0px" }}
                                  >
                                    Download Now
                                  </a>
                                </InputGroup>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-center my-4">
                          <button
                            className="btn btn-lg btn-primary"
                            type="submit"
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h4>No Rebranding Setting Available</h4>
              <p>
                For rebranding please contact on us on{" "}
                <b>
                  <a
                    className="text-primary"
                    href={"tel:" + process.env.REACT_APP_PHONE_NUMBER}
                  >
                    {process.env.REACT_APP_PHONE_NUMBER}
                  </a>
                </b>{" "}
                or email us on{" "}
                <b>
                  <a
                    className="text-primary"
                    href={"mailto:" + process.env.REACT_APP_EMAIL_ID}
                  >
                    {process.env.REACT_APP_EMAIL_ID}.
                  </a>
                </b>
                <br />
                <br />
                OR
                <br />
                <br />
                <a
                  className="btn btn-sm btn-primary"
                  target="_blank"
                  href={process.env.REACT_APP_CONTACT_URL}
                >
                  Buy Rebrand Package
                </a>
              </p>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Setting;
