import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  Spinner,
  Table,
  Pagination,
  Col,
  Row,
  Card,
  Badge,
  Dropdown,
  Tab,
  Nav,
  Modal,
  ListGroup,
} from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import axiosInstance from "../../../services/AxiosInstance";

import swal from "sweetalert";

import { getProfile } from "../../../store/actions/AuthActions";

import { getStatistics } from "../../../store/actions/licActions";

import { getAllProducts } from "../../../store/actions/licActions";

// import Ext from "../../layouts/Ext";

const Home = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.lic.products);
  //const [statistics, setStatistics] = useState(null);
  const profile = useSelector((state) => state.auth.profile);

  const statistics = useSelector((state) => state.lic.statistics);

  const [profileModal, setProfileModal] = useState(false);
  let errorsObj = {
    name: "",
    addLine1: "",
    addLine2: "",
    addCity: "",
    addState: "",
    addLandmark: "",
    addZipcode: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [addLine1, setAddLine1] = useState("");
  const [addLine2, setAddLine2] = useState("");
  const [addCity, setAddCity] = useState("");
  const [addState, setAddState] = useState("");
  const [addCountry, setAddCountry] = useState("India");
  const [addLandmark, setAddLandmark] = useState("");
  const [addZipcode, setAddZipcode] = useState("");

  useEffect(() => {
    //dispatch(getProfile());
    dispatch(getAllProducts());
    loadDetails();
    //loadStatistics();
    dispatch(getStatistics());
  }, [dispatch]);


  const loadDetails = async () => {
    const { data } = await axiosInstance.get("auth/profile");

    if (data.profile) {
      //setProfile(data.user);
      const profile = data.profile;
      setName(profile.name ?? "");
      setEmail(profile.email ?? "");

      if (profile.address) {
        setAddLine1(profile.address.addressLine1 ?? "");
        setAddLine2(profile.address.addressLine2 ?? "");
        setAddCity(profile.address.city ?? "");
        setAddState(profile.address.state ?? "");
        setAddZipcode(profile.address.zip ?? "");
        setAddCountry(profile.address.country ?? "India");
      }

      if (profile.name === "") {
        setProfileModal(true);
      }
    }

    console.log("details data - " + JSON.stringify(data));
  };

  /*    async function loadStatistics(){
		
      try {
         
         const { data } = await axiosInstance.get(
              "reseller/statistics"
         );
   

     console.log("statistics data - "+JSON.stringify(data));
   
      if(data.status === false){
         
      }else{
         setStatistics(data);
         
      }
      
      }catch (error) {
         
   
      }
   
     } */

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="owl-next" onClick={onClick} style={{ zIndex: 99 }}>
        <i className="fa fa-caret-right" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className="owl-prev disabled"
        onClick={onClick}
        style={{ zIndex: 99 }}
      >
        <i className="fa fa-caret-left" />
      </div>
    );
  }

  const settings = {
    focusOnSelect: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const onSaveProfile = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (name === "") {
      errorObj.name = "Name is Required";
      error = true;
    }

    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }

    if (addLine1 === "") {
      errorObj.addLine1 = "Address Line 1 is Required";
      error = true;
    }

    if (addCity === "") {
      errorObj.addCity = "Address City is Required";
      error = true;
    }
    if (addState === "") {
      errorObj.addState = "Address State is Required";
      error = true;
    }

    if (addCountry === "") {
      errorObj.addCouny = "Address Country is Required";
      error = true;
    }

    if (addZipcode === "") {
      errorObj.addZipcode = "Address Zip Code is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      console.log("error on save");
      return;
    }
    console.log("no error on save");
    //save data

    const formData = {
      name: name,
      email: email,
      add_line_1: addLine1,
      add_line_2: addLine2,
      add_city: addCity,
      add_state: addState,
      add_zipcode: addZipcode,
    };

    console.log("form data - " + formData);

    const { data } = await axiosInstance.post(
      "reseller/update-details",
      formData
    );

    if (data.status) {
      swal("Update Profile", data.message, "success");
      setProfileModal(false);
    } else {
      swal("Update Profile", data.message, "error");
    }
  };

  const productName = (p) => {
    const product = products.find((x)=>x._id == p._id);
    if(product){
      return product.name;
    }else{
      return p.name;
    }
  }

  return (
    <>
      <Modal
        className="modal fade"
        show={profileModal}
        onHide={setProfileModal}
        size="xl"
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onSaveProfile}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Update Profile</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setProfileModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label>Name</label>
                        <input
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                          className="form-control"
                        />
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <label>Email</label>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                          className="form-control"
                        />
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Address Line 1</label>
                      <input
                        type="text"
                        value={addLine1}
                        onChange={(e) => setAddLine1(e.target.value)}
                        placeholder="e.g. 1234 Main St"
                        className="form-control"
                      />
                      {errors.addLine1 && (
                        <div className="text-danger fs-12">
                          {errors.addLine1}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Address Line 2</label>
                      <input
                        type="text"
                        value={addLine2}
                        onChange={(e) => setAddLine2(e.target.value)}
                        placeholder="e.g. Apartment, studio, or floor"
                        className="form-control"
                      />
                      {errors.addLine2 && (
                        <div className="text-danger fs-12">
                          {errors.addLine2}
                        </div>
                      )}
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <label>City</label>
                        <input
                          value={addCity}
                          onChange={(e) => setAddCity(e.target.value)}
                          type="text"
                          className="form-control"
                        />
                        {errors.addCity && (
                          <div className="text-danger fs-12">
                            {errors.addCity}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-3">
                        <label>State</label>

                        <input
                          value={addState}
                          onChange={(e) => setAddState(e.target.value)}
                          type="text"
                          className="form-control"
                        />

                        {errors.addState && (
                          <div className="text-danger fs-12">
                            {errors.addState}
                          </div>
                        )}
                      </div>

                      <div className="form-group col-md-3">
                        <label>Country</label>

                        <input
                          value={addCountry}
                          onChange={(e) => setAddCountry(e.target.value)}
                          type="text"
                          className="form-control"
                        />

                        {errors.addContry && (
                          <div className="text-danger fs-12">
                            {errors.addCountry}
                          </div>
                        )}
                      </div>

                      <div className="form-group col-md-3">
                        <label>Zip Code</label>
                        <input
                          type="text"
                          value={addZipcode}
                          onChange={(e) => setAddZipcode(e.target.value)}
                          className="form-control"
                        />
                        {errors.addZipcode && (
                          <div className="text-danger fs-12">
                            {errors.addZipcode}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setProfileModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <div className="row">
        <div className="col-xl-3 col-md-6">
          <div className="card bordered-card">
            <div className="card-body p-4">
              <div className="d-flex flex-wrap align-items-center text-head">
                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                  {statistics.totalKeys ?? 0}
                </h2>
                <span className="circle-1 bg-primary mt-4" />
              </div>

              <span className="fs-14">Total Keys</span>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card bordered-card">
            <div className="card-body p-4">
              <div className="d-flex flex-wrap align-items-center text-head">
                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                  {statistics.unusedKeys ?? 0}
                </h2>
                <span className="circle-1 bg-primary mt-4" />
              </div>

              <span className="fs-14">Available Keys</span>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-4 col-sm-6">
          <div className="card bordered-card">
            <div className="card-body p-4">
              <div className="d-flex flex-wrap align-items-center text-head">
                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                  {statistics.activeKeys ?? 0}
                </h2>
                <span className="circle-1 bg-success mt-4" />
              </div>

              <span className="fs-14">Active Keys</span>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 col-sm-6">
          <div className="card bordered-card">
            <div className="card-body p-4">
              <div className="d-flex flex-wrap align-items-center text-head">
                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                  {statistics.expireKeys ?? 0}
                </h2>
                <span className="circle-1 bg-danger mt-4" />
              </div>

              <span className="fs-14">Expired Keys</span>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card bordered-card">
            <div className="card-body p-4">
              <div className="d-flex flex-wrap align-items-center text-head">
                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                  {statistics.trialKeys ?? 0}
                </h2>
                <span className="circle-1 bg-warning mt-4" />
              </div>

              <span className="fs-14">Trial Keys</span>
            </div>
          </div>
        </div>
      </div>

      {process.env.REACT_APP_SHOW_TERMS === "true" ? (
        <div class="row">
          <div class="col-md-12">
            <Card>
              <Card.Body>
                <Card.Title>Rules For Resellers</Card.Title>
              </Card.Body>
              <ListGroup variant="flush" className="reseller-rules">
                {/* <ListGroup.Item>
                  1. You have to sell minimum <span>1 license/month.</span>
                </ListGroup.Item> */}
                {/* <ListGroup.Item>
                  2. If minimum sales target will not met then your reseller
                  account will permanently deactivated.
                </ListGroup.Item> */}
                <ListGroup.Item>
                  1. If we found that, you take reseller panel for only own
                  purpose not for selling, then we will deactivate all activate
                  licenses & panel without any inform.
                </ListGroup.Item>
                <ListGroup.Item>
                  2. Do not share your reseller keys price or any other
                  information with anyone, if we found this then we will
                  deactivate all activate licenses & panel without any inform.
                </ListGroup.Item>
                <ListGroup.Item>
                  3. No full version keys will be deleted after created. if you
                  created in panel then you have pay.
                </ListGroup.Item>
                <ListGroup.Item>
                  4. <span>We dont have refund policy.</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  5. Create full version keys with genuine Name, Email & Mobile
                  Number details. If we found any fake data then we immediately
                  delete the license.
                </ListGroup.Item>
                <ListGroup.Item>
                  6. Create reseller account with genuine{" "}
                  <span>Name, Email, Mobile Number</span> details. If we found
                  any fake data then we will immediately account and license.
                </ListGroup.Item>
                <ListGroup.Item>
                  7. We dont provide any full version keys to resellers for
                  customer demo purpose. You have to buy keys for customer demo
                  purpose also.
                </ListGroup.Item>
                <ListGroup.Item>
                  8. Initially we help you for support for your customer, But
                  you have to learn it from own then you have to give support to
                  customer for future need.
                </ListGroup.Item>
                {/* <ListGroup.Item>
                  9. Reseller videos/images material for marketing purpose
                  download link -{" "}
                  <span>https://www.youtube.com/c/RADHESOLUTION</span>
                </ListGroup.Item> */}
                <ListGroup.Item>
                  9. If want to create reseller under you then you have to
                  maintain same above rules with him.
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </div>
        </div>
      ) : (
        <></>
      )}

      <>
        {!statistics.products ? (
          <></>
        ) : (
          statistics.products
            .filter((x) => x.enable)
            .map((product, i) => {
              //return <h4>{product.name??"Not found"}</h4>;

              return (
                <div className="card bordered-card" key={"product-state-" + i}>
                  <div class="card-header">
                    <div class="card-title h5">{productName(product)}</div>
                  </div>

                  <div className="card-body p-4">
                    <div className="row mt-4">
                      <div className="col-xl-3 col-md-6">
                        <Link to={"/license-keys/" + product.code}>
                          <div className="card bordered-card">
                            <div className="card-body p-4">
                              <div className="d-flex flex-wrap align-items-center text-head">
                                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                                  {product.keys ?? 0}
                                </h2>
                                <span className="circle-1 bg-primary mt-4" />
                              </div>

                              <span className="fs-14">Total Keys</span>
                            </div>
                          </div>
                        </Link>
                      </div>

                      <div className="col-xl-3 col-md-6">
                        <Link to={"/license-keys/" + product.code}>
                          <div className="card bordered-card">
                            <div className="card-body p-4">
                              <div className="d-flex flex-wrap align-items-center text-head">
                                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                                  {product.availableKeys ?? 0}
                                </h2>
                                <span className="circle-1 bg-primary mt-4" />
                              </div>

                              <span className="fs-14">Available Keys</span>
                            </div>
                          </div>
                        </Link>
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <Link to={"/license-keys/" + product.code}>
                          <div className="card bordered-card">
                            <div className="card-body p-4">
                              <div className="d-flex flex-wrap align-items-center text-head">
                                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                                  {product.activeKeys ?? 0}
                                </h2>
                                <span className="circle-1 bg-success mt-4" />
                              </div>

                              <span className="fs-14">Active Keys</span>
                            </div>
                          </div>
                        </Link>
                      </div>

                      <div className="col-xl-3 col-md-6 col-sm-6">
                        <Link to={"/license-keys/" + product.code}>
                          <div className="card bordered-card">
                            <div className="card-body p-4">
                              <div className="d-flex flex-wrap align-items-center text-head">
                                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                                  {product.expireKeys ?? 0}
                                </h2>
                                <span className="circle-1 bg-danger mt-4" />
                              </div>

                              <span className="fs-14">Expired Keys</span>
                            </div>
                          </div>
                        </Link>
                      </div>

                      <div className="col-xl-3 col-md-6">
                        <Link to={"/trial-license-keys/" + product.code}>
                          <div className="card bordered-card">
                            <div className="card-body p-4">
                              <div className="d-flex flex-wrap align-items-center text-head">
                                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                                  {product.trialKeys ?? 0}
                                </h2>
                                <span className="circle-1 bg-warning mt-4" />
                              </div>

                              <span className="fs-14">Trial Keys</span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              );
            })
        )}
      </>
    </>
  );
};

export default Home;
