import React, {  useRef,useState,useEffect ,Fragment } from "react";
import { Table, Pagination, Badge,Dropdown,Spinner,Modal } from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";

import { useDispatch,useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from '../../../services/AxiosInstance';
import swal from "sweetalert";

import { format } from "date-fns";

import {CopyToClipboard} from 'react-copy-to-clipboard';

import { CSVLink } from "react-csv";



import {
   EmailShareButton,
   FacebookShareButton,
   LinkedinShareButton,
   TelegramShareButton,
   TwitterShareButton,
   WhatsappShareButton,

   EmailIcon,
   FacebookIcon,
   LinkedinIcon,
   TelegramIcon,
   TwitterIcon,
   WhatsappIcon,
 } from "react-share";

 import {
   getProfile,
} from '../../../store/actions/AuthActions';

//const Orders = () => {
const SubAgents = ({ props, history }) => {

   const dispatch = useDispatch();
   const [transferModal, setTransferModal] = useState(false);
   const [activeMember, setActiveMember] = useState("");
   const [users, setUsers] = useState([]);
   const [loading, setLoading] = useState(false);
   const [inviteModal, setInviteModal] = useState(false);
   const [copied, setCopied] = useState(false);


   const [search, setSearch] = useState("");
   const [searchTimeout, setSearchTimeout] = useState(null);
   const [xlsData, setXlsData] = useState([]);

      const profile = useSelector((state) => state.auth.profile);

   const sort = 500;
   let pagination = Array(Math.ceil(users.length / sort))
   .fill()
   .map((_, i) => i + 1);

const [activePage, setActivePage] = useState(0);
const [tableData, setTableData] = useState([]);
  
  
   useEffect(() => {
		loadUsers();
      dispatch(getProfile());
	}, [dispatch]);


   useEffect(() => {

      if(users){
         setLoading(false);
         setTableData( users.slice(
            activePage * sort,
            (activePage + 1) * sort
         ));
      }
     
	}, [users]);

   useEffect(() => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    
      setSearchTimeout(
        setTimeout(() => {
         loadUsers();
        }, 1000)
      );
    
      return () => clearTimeout(searchTimeout);
    }, [search]);


   const loadUsers = async () => {

      const { data } = await axiosInstance.post(
         "agent/sub-agents",
         {"search":search}
       );
 
       if(data.status){
         setUsers(data.agents);
       }
   }

    // transfer counter 
    const [transferCounterFormData, setTransferCounterFormData ] = useState({
      email:"",
      counter:''
  }); 
  
  // update member function
  const handleTransferCounterFormChange = (event) => {
      event.preventDefault();    
      const fieldName = event.target.getAttribute('name');
      const fieldValue = event.target.value;
      const newFormData = {...transferCounterFormData};
      newFormData[fieldName] = fieldValue;
      setTransferCounterFormData(newFormData);
  };

       // Update Profile Submit data
       const handleTransferCounterFormSubmit = async (event)=> {
         event.preventDefault();
         
         var error = false;
         var errorMsg = '';
 
       if(transferCounterFormData.email === ""){
             error = true;
          errorMsg = 'Email is Required';
       }else if(transferCounterFormData.counter === ""){
          error = true;
          errorMsg = 'Counter is Required';
       }
 
         if(!error){
             
         
         try {
			
            const { data } = await axiosInstance.post(
               "agent/transfer-counter",
               { type:"email",counter:transferCounterFormData.counter,email:transferCounterFormData.email }
            );
   

        console.log("agent/transfer-counter - "+JSON.stringify(data));
   
        if(data.status === false){
           //toast.error(data.message);
         swal('Transfer Counter', data.message,"error");

        }else{
           //toast.success(data.message);
         swal('Transfer Counter', data.message, "success");

         transferCounterFormData.email = '';
         transferCounterFormData.counter = "";     
       
           setTimeout(() => {
              setTransferModal(false);
              //dispatch(getInvitationsAction());
              loadUsers();

            }, 500)

         
          }
         
         }catch (error) {
			
         }


                   
             
         }else{
          swal('Oops', errorMsg, "error");
       }
     };

      // On Edit Profile
      const onSendCounter= (member) => {
         const newFormData = {email:member.email,counter:0};
         setTransferCounterFormData(newFormData);
         setActiveMember(member.id);
         setTransferModal(true);
      }
   
   
   const removeSubAgent = async (userId) => {

      const { data } = await axiosInstance.post(
         "agent/remove-sub-agent",
         {
            user_id:userId
         }
      );

      if(data.status){
         swal('Sub Aggent', data.message, "success");

         var dummy = users.filter(value => value.id !== userId);

         setUsers(dummy);
         onClick(activePage);

      }else{
         swal('Sub Aggent', data.message, "error");
      }

   }


   //const [demo, setdemo] = useState();
   const onClick = (i) => {
      console.log("onClick - "+i);
      setActivePage(i);
      setTableData(users.slice(
         i * sort,
         (i + 1) * sort
      ));
   };


   function shareTitle(){
      return "pcv2print invite";
   }

   function shareBody(){
      //return "pcv2print";
      return "Hi! I am sending you invite link of pvc2print App. Sign up on the pvc2print App using my link to claim the free credits.\n\npvc2print App provide PVC card printing functionality using 3 steps only. One App for multiple Card Types. pvc2print support all card printer.\n\nHow pvc2print work?\n1.0 Upload PDF\n2.0 Select your printer type\n3.0 Preview & Print/Share\n\n";
   }

   function shareBody2(){
      //return "pcv2print";
      return "Hi! I am sending you invite link of pvc2print App. Sign up on the pvc2print App using my link to claim the free credits.\n\nHow pvc2print work?\n1.0 Upload PDF\n2.0 Select your printer type\n3.0 Preview & Print/Share\n\n";
   }

   function referLink(){
      return "https://agent.pvc2print.com/sign-up?referCode="+(profile ? profile.referCode :"");
   }

   
   const initXlsData = async () => {

      var dummy = [];
      users.map((data,i)=>{

         dummy.push({
            sr_no:i+1,
            name:data.name,
            phone:data.phone == data.email ? "" : data.phone,
            email:data.email,
            counter:data.counter,
            created_at:format(new Date(data.createdAt), "dd/MM/yyyy H:mma"),
         });
      });


      setXlsData(dummy);
   }
   

   
   return (

      <>

      
            
<Modal className="modal fade"  show={inviteModal} onHide={setInviteModal} >
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Invite Sub Agent</h4>
									<button type="button" className="close" onClick={()=> setInviteModal(false)}><span>×</span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">

                                 <p>Your refer code:</p>
                                 <h3>{profile ? profile.referCode :"----"}</h3>

                                

                                 <p>{referLink()}</p>
                                 <CopyToClipboard text={referLink()}
                                    onCopy={() => {
                                       setCopied(true);
                                       setTimeout(() => {
                                          setCopied(false);
                                        }, 1500);
                                    
                                    }}>
                                    <div className='d-flex'><button className="btn btn-sm btn-light mr-2">Copy link</button>{copied?(<span className='mt-2' style={{color: 'red'}}>Copied.</span>):""}</div>
                                 </CopyToClipboard>

                                 <div className='row mt-4'>

                                 <div className='col-md-2'>
                                    <EmailShareButton url={referLink()} 
                                    body={shareBody()} 
                                    subject={shareTitle()}>
                                    
                                 <EmailIcon size={32} round />

                                    </EmailShareButton></div>

                                 <div className='col-md-2'> <FacebookShareButton
                                 url={referLink()}
                                 quote={shareBody()} hashtag='#pvc2print'>

                                 <FacebookIcon size={32} round />


                                 </FacebookShareButton>
                                 </div>
                                 <div className='col-md-2'><LinkedinShareButton url={referLink()} title={shareTitle()} summary={shareBody()}>

                                 <LinkedinIcon size={32} round />
                                 </LinkedinShareButton>
                                </div>
                                 <div className='col-md-2'><TelegramShareButton url={referLink()} title={shareBody()}>

                                 <TelegramIcon size={32} round />

                                 </TelegramShareButton>
                                 </div>
                                 <div className='col-md-2'><TwitterShareButton url={referLink()} title={shareBody2()}>

                                 <TwitterIcon size={32} round />
                                 </TwitterShareButton>
                                 </div>
                                 <div className='col-md-2'><WhatsappShareButton url={referLink()} title={shareBody()} >

                                 <WhatsappIcon size={32} round />

                                 </WhatsappShareButton>
			</div>
                                 </div>
                                 
                                 
										</div>
									</div>
								</div>
								
							</form>
                            
						</div>
					</div>
				</Modal>


      
      <Modal className="modal fade"  show={transferModal} onHide={setTransferModal} >
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Transfer Counter</h4>
									<button type="button" className="close" onClick={()=> setTransferModal(false)}><span>×</span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">


										<div className="form-group mb-3">
												<label className="text-black font-w500">Email Address</label>
												<div className="contact-name">
													<input type="email" value={transferCounterFormData.email}  className="form-control"  autocomplete="off"
														name="email" required="required"
                                                        onChange={handleTransferCounterFormChange}
														placeholder="email"
													/>
													<span className="validation-text"></span>
												</div>
											</div>

											
											 <div className="form-group mb-3">
												<label className="text-black font-w500">Counter</label>
												<div className="contact-name">
													<input type="number" value={transferCounterFormData.counter}  className="form-control"  autocomplete="off"
														name="counter" onkeypress="return event.charCode>=48 && event.charCode<=57" required="required"
                                                        onChange={handleTransferCounterFormChange}
														placeholder="Counter"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
                                          
			
										</div>
									</div>
								</div>
								<div className="modal-footer">
                                     
                                    <button type="button" onClick={()=> setTransferModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>   
                                    <button type="submit" className="btn btn-primary" onClick={handleTransferCounterFormSubmit}>Transfer</button>    
								</div>
							</form>
                            
						</div>
					</div>
				</Modal>
     

            {profile?(<Fragment>
         {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

      <div className="col-12">
         <div className="card">
            <div className="card-header">
               <h4 className="card-title mr-auto">Sub Agents</h4>


      
               <div className="input-group search-area d-lg-inline-flex d-none mr-2">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (searchTimeout) {
                      clearTimeout(searchTimeout);
                    }
                    loadUsers();
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </form>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                        fill="#A4A4A4"
                      />
                    </svg>
                  </span>
                </div>
              </div>

               

               <div class="d-flex justify-content-end">

                        
                        
               {profile.agentLevel <=2 ? <Link className="btn btn-light font-w600 mr-2" onClick={()=> setInviteModal(true)}>Invite Agent</Link> : <></>}

               <CSVLink
  data={xlsData}
  filename={"agents.csv"}
  className="btn btn-primary"
  target="_blank"
  asyncOnClick={true}
  onClick={async (event, done) => {
   console.log("You click the link");
   await initXlsData();
   done(); // 👍🏻 You are stopping the handling of component
 }}

>
  Export
</CSVLink> 


</div>


            </div>
            <div className="card-body">

               {loading ? <div className="text-center mt-4"><Spinner animation="border" variant="primary" /></div>:(
       
       profile.agentLevel > 2 ? (<p>You can't add Sub Agent.</p>):
       users.length == 0 ? <p>No Sub Agent Found</p>:
       <Table responsive className="w-100">
       <div id="example_wrapper" className="dataTables_wrapper">
          <table id="example" className="display w-100 dataTable">
             <thead>
                <tr role="row">
                              <th>
                                 <strong>SR NO.</strong>
                              </th>
                              <th>
                                 <strong>Name</strong>
                              </th>

                              <th>
                                 <strong>Phone</strong>
                              </th>

                              <th>
                                 <strong>Email</strong>
                              </th>

                              <th>
                                 <strong>Counters</strong>
                              </th>

                              <th>
                                 <strong>Joined At</strong>
                              </th>

                              <th>
                                 <strong>Action</strong>
                              </th>
                </tr>
             </thead>
             <tbody>
                {tableData.map((d, i) => (
                   <tr key={i}>

                      {/* <td ><Link to={"/user/"+d.id}>#{d.id}</Link></td> */}
                      <td >{i+1}</td> 
                      <td>{d.name}</td>
                      <td>{d.phone == d.email ? "":d.phone}</td>
                      <td>{d.email}</td>

                      <td><strong>{d.counter}</strong></td>
                     
                      <td>{ format(new Date(d.createdAt), "dd/MM/yyyy H:mma")}</td>
                      
                     
                      <td>

                      <Dropdown>
                                  <Dropdown.Toggle
                                     variant=""
                                     className="table-dropdown icon-false"
                                  >
                                     <svg
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                     >
                                        <g
                                           stroke="none"
                                           strokeWidth="1"
                                           fill="none"
                                           fillRule="evenodd"
                                        >
                                           <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                           ></rect>
                                           <circle
                                              fill="#000000"
                                              cx="5"
                                              cy="12"
                                              r="2"
                                           ></circle>
                                           <circle
                                              fill="#000000"
                                              cx="12"
                                              cy="12"
                                              r="2"
                                           ></circle>
                                           <circle
                                              fill="#000000"
                                              cx="19"
                                              cy="12"
                                              r="2"
                                           ></circle>
                                        </g>
                                     </svg>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>

                                  <Dropdown.Item as="button" onClick={(e)=>onSendCounter(d)}>
                                                   Transfer Counter
                                                </Dropdown.Item>

                                     <Dropdown.Item as="button" onClick={(e)=>removeSubAgent(d.id)}>
                                          Remove Sub Agent
                                     </Dropdown.Item>
                                    
                                    {/*  <div className="dropdown-divider"></div>
                                     <Dropdown.Item
                                        href="#"
                                        className="text-danger"
                                     >
                                        Delete
                                     </Dropdown.Item> */}
                                  </Dropdown.Menu>
                               </Dropdown>

                      </td>

                      
                   </tr>
                ))}
             </tbody>
             <tfoot>
                <tr role="row">

                              <th>
                                 <strong>SR NO.</strong>
                              </th>
                              <th>
                                 <strong>Name</strong>
                              </th>

                              <th>
                                 <strong>Phone</strong>
                              </th>

                              <th>
                                 <strong>Email</strong>
                              </th>

                              <th>
                                 <strong>Counters</strong>
                              </th>

                              <th>
                                 <strong>Joined At</strong>
                              </th>

                              <th>
                                 <strong>Action</strong>
                              </th>

                </tr>
             </tfoot>
          </table>
          <div className="d-flex justify-content-between align-items-center mt-3">
             <div className="dataTables_info">
                Showing {activePage * sort + 1} to 
                {users.length <
                (activePage + 1) * sort
                   ? users.length
                   : (activePage + 1) * sort}
                &nbsp;of {users.length} entries
             </div>
             <div className="dataTables_paginate paging_simple_numbers">
                <Pagination
                   className="pagination-primary pagination-circle"
                   size="lg"
                >
                   <li
                      className="page-item page-indicator "
                      onClick={() =>
                         activePage > 1 &&
                         onClick(activePage - 1)
                      }
                   >
                      <Link className="page-link" to="#">
                         <i className="la la-angle-left" />
                      </Link>
                   </li>
                   {pagination.map((number, i) => (
                         <Pagination.Item
                         key={"page-"+i}
                         className={
                            activePage === i ? "active" : ""
                         }
                         onClick={() => onClick(i)}
                      >
                         {number}
                      </Pagination.Item>
                   ))}
                   <li
                      className="page-item page-indicator"
                      onClick={() =>
                         activePage + 1 <
                            pagination.length &&
                         onClick(activePage + 1)
                      }
                   >
                      <Link className="page-link" to="#">
                         <i className="la la-angle-right" />
                      </Link>
                   </li>
                </Pagination>
             </div>
          </div>
       </div>
    </Table>
       
              )}
       


             
         </div>
      </div>
      </div>

      </Fragment>):<></>}

      </>
   );
};

export default SubAgents;
